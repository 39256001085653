<template>
  <CrudCardTable
    title="Menus"
    :items="instances"
    :headers="headers"
    :button-to="{name: 'App-MenuCreator-Menus-New'}"
  >
    <template #item.name="{ item }">
      <router-link :to="{ name: 'App-MenuCreator-Menus-id', params: { id: item.id } }">
        {{ item.name }}
      </router-link>
    </template>
    <template #item.categories="{ item }">
      <v-chip-group>
        <v-chip
          v-for="menuCategory in item.menuCategories"
          :key="menuCategory.$id"
        >
          {{ menuCategory.category ? menuCategory.category.name : "" }}
        </v-chip>
      </v-chip-group>
    </template>
  </CrudCardTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'

export default {
  name: 'MenuList',
  components: { CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Is Active', value: 'isActive' },
        { text: 'Categories', value: 'categories' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['menus']),
    instances () {
      return this.menus.all()
    }
  }
}
</script>
